import * as PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';

import { capitalize } from '../helpers/utils';
import CityCanadaDetail from '../components/cityCanadaDetail';
import Sidebar from '../components/Sidebar/sidebar';
import FlightCardsList from '../components/FlightCards/FlightCardsList';
import CallUs from '../components/CallUs/CallUs';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import configurePageData from '../helpers/configurePageData';
import Layout from '../components/layout';
import Trustpilot from '../components/Trustpilot';
import SubscribeForm from '../components/SubscribeForm';
import SEO from '../components/seo';

import { CURRENCY } from '../constants/currency';
import { isFlykart } from '../helpers/urlParams';

class CityTemplate extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      cityCanadaJson: PropTypes.object.isRequired,
      allMarkdownRemark: PropTypes.object,
      aImage: PropTypes.object,
      fImage: PropTypes.object
    }),
    configuredData: null
  };

  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '',
      configuredData: null
    };
  }

  async componentDidMount() {
    const dataConfig = await configurePageData(
      this.props.data.cityCanadaJson.slug
    );
    if (dataConfig) {
      this.setState({
        configuredData: dataConfig
      });
    }
  }

  render() {
    const {
      cityCanadaJson: cityCanadaJSON,
      allMarkdownRemark
    } = this.props.data;

    const cityCanadaJson = {
      ...cityCanadaJSON,
      ...(this.state.configuredData && this.state.configuredData)
    };

    const { phoneNumber } = this.state;
    const path = [
      ...cityCanadaJson.path,
      { name: capitalize(cityCanadaJson.title), slug: cityCanadaJson.slug }
    ];
    const countryName =
      cityCanadaJson.path.length >= 1
        ? capitalize(cityCanadaJson.path[cityCanadaJson.path.length - 1].name)
        : '';
    const title = `Flights to ${capitalize(cityCanadaJson.title)} (${
      cityCanadaJson.pairs[0].destinationIATA
    }), ${countryName} from $${cityCanadaJson.pairs[0].price}`;
    const slug = cityCanadaJson.slug;
    const description = `Find cheap flights to city (${cityCanadaJson.pairs[0].destinationIATA}) from ${CURRENCY.CANADA}${cityCanadaJson.pairs[0].price}. Book online at travelopod.com or call us 24/7 for live booking assistance
    and last minute travel deals.`;

    const to = capitalize(cityCanadaJson.title);
    const layoutTitle = `Find Cheap Flights to ${to}`;
    const flightCardsListTitle = `Cheap flights to ${to} from ${CURRENCY.CANADA}${cityCanadaJson.pairs[0].price}`;
    const flykartTheme = isFlykart();
    const img = flykartTheme
      ? this.props.data.fImage.childImageSharp.fluid
      : this.props.data.aImage.childImageSharp.fluid;
    return (
      <Layout
        pageData={cityCanadaJSON}
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
        title={layoutTitle}
        image={img}
      >
        <SEO title={title} description={description} slug={slug} />
        <div className="main">
          <Breadcrumbs path={path} />
          <div className="page-content">
            <div className="flight-content">
              <FlightCardsList
                city={cityCanadaJson}
                title={flightCardsListTitle}
                cheapestPrice={cityCanadaJson.pairs[0].price}
              />
              <CallUs phoneNumber={phoneNumber} />
              <CityCanadaDetail
                city={cityCanadaJson}
                htmlContent={
                  allMarkdownRemark.edges[0]
                    ? allMarkdownRemark.edges[0].node.html
                    : null
                }
              />
            </div>
            <Sidebar destination={capitalize(cityCanadaJson.title)} />
          </div>
          <SubscribeForm isInnerPage />
        </div>
        <Trustpilot />
      </Layout>
    );
  }
}

export default CityTemplate;

export const pageQuery = graphql`
  query($title: String!, $image: String) {
    cityCanadaJson(title: { eq: $title }) {
      ...City_Canada_details
    }
    allMarkdownRemark(filter: { frontmatter: { page: { eq: $title } } }) {
      edges {
        node {
          html
          frontmatter {
            title
            date
          }
        }
      }
    }
    aImage: file(relativePath: { in: [$image, "flight-background.jpg"] }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    fImage: file(relativePath: { in: [$image, "flykart-img/flykart-bg.jpg"] }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
